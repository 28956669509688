<template>
  <div v-if="!isSending" class="mb-16">
    <calendesk-wrapper>
      <v-container fluid class="table-filters">
        <v-row align="center">
          <v-col cols="12">
            <calendesk-information-message>
              {{ $trans("availability_list_info") }}
            </calendesk-information-message>
          </v-col>
        </v-row>
      </v-container>
      <availability-filter
        @filtersChanged="filtersChanged"
        @filtersRestored="filtersRestored"
      />
      <v-data-table
        v-model="selected"
        :footer-props="footerProps"
        :headers="headers"
        :items="items"
        :loading="isLoadingTable"
        :loading-text="$trans('loading')"
        :no-data-text="$trans('nothing_found_here')"
        :no-results-text="$trans('nothing_found_here')"
        :options.sync="tableOptions"
        :server-items-length="total"
        :show-select="!noData"
        class="calendesk-datatable"
        mobile-breakpoint="960"
      >
        <template #[`header.data-table-select`]>
          <div class="text-center">
            <v-simple-checkbox
              v-model="selectAllState"
              :ripple="false"
              :indeterminate="isIndeterminateForSelectAll"
              color="primary"
              @input="selectAllItemsOnChange"
            />
          </div>
        </template>

        <template #[`item`]="{ item }">
          <availability-row
            :item="item"
            :selection-map="(item) => item"
            :is-selected="
              isRowSelected({
                id: item.id,
              })
            "
            @selectOnChange="itemRowEventOnSelectChange"
          />
        </template>
      </v-data-table>
      <table-footer-menu
        :on-select-actions="onSelectActions"
        :selected="selected"
        :selection-map="(item) => item"
      />
    </calendesk-wrapper>
  </div>
</template>

<script>
import sharedList from "@/calendesk/mixins/sharedList";
import TableFooterMenu from "@/components/TableFooterMenu.vue";
import availabilityActions from "@/calendesk/mixins/availabilityActions";
import AvailabilityFilter from "@/views/dashboard/pages/Availability/components/AvailabilityFilter.vue";
import AvailabilityRow from "@/views/dashboard/pages/Availability/components/AvailabilityRow.vue";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";
import CalendeskWrapper from "@/components/CalendeskWrapper.vue";

export default {
  components: {
    CalendeskWrapper,
    CalendeskInformationMessage,
    AvailabilityRow,
    AvailabilityFilter,
    TableFooterMenu,
  },
  mixins: [availabilityActions, sharedList],
  data() {
    return {
      cacheId: "availability-list",
      requestActionName: "fetchAllAvailabilitySchedules",
      headers: [
        {
          text: this.$trans("id"),
          align: "start",
          sortable: true,
          value: "id",
          class: "header-style",
        },
        {
          text: this.$trans("schedule_name"),
          value: "name",
          sortable: true,
          class: "header-style",
        },
        {
          text: this.$trans("assigned_to_employees"),
          value: "employees-selectable",
          class: "header-style",
          sortable: false,
        },
        {
          text: this.$trans("assigned_to_services"),
          value: "services-selectable",
          class: "header-style",
          sortable: false,
        },
        {
          text: this.$trans("created_at"),
          value: "created_at",
          class: "header-style",
        },
        {
          text: this.$trans("manage"),
          value: "actions",
          sortable: false,
          class: "header-style",
          align: "end",
        },
      ],
    };
  },
  computed: {
    onSelectActions() {
      return [
        {
          title: { single: "remove", many: "remove" },
          action: this.handleDeleteAvailabilities,
          target: "availability",
          class: "accent_light--text",
        },
      ];
    },
  },
  watch: {
    getRefreshSchedulesListStatus(status) {
      if (status) {
        this.forceReload();
      }
    },
  },
};
</script>
