<template>
  <v-container class="table-filters" fluid>
    <v-row>
      <v-col v-if="loggedUserCanSeeAllEmployees" cols="12" sm="3">
        <employee-field
          v-model="selectedFiltersEmployees"
          :label="$trans('employee')"
          dense
          :disabled="disabled"
          small-chips
          deletable-chips
          multiple
          keep-menu-open-on-select
        />
      </v-col>
      <v-col cols="12" sm="3">
        <services-field
          v-model="selectedFiltersServiceIds"
          small-chips
          multiple
          deletable-chips
          dense
          return-ids
          keep-menu-open-on-select
        />
      </v-col>
      <v-col cols="12" :sm="loggedUserCanSeeAllEmployees ? 6 : 9">
        <v-text-field
          v-model="selectedFiltersSearch"
          :label="$trans('search')"
          :placeholder="$trans('autocomplete_hint')"
          append-icon="$magnify"
          hide-details
          outlined
          single-line
          clearable
          :disabled="disabled"
          dense
        />
      </v-col>
    </v-row>
    <v-row class="my-4" justify="center">
      <v-col class="d-flex align-center justify-center" cols="12" md="3" sm="6">
        <v-btn
          color="primary"
          outlined
          small
          :disabled="disabled"
          @click="clearFilters"
        >
          {{ $trans("clear_filters") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sharedListFilters from "@/calendesk/mixins/sharedListFilters";
import { mapGetters } from "vuex";
import EmployeeField from "@/views/dashboard/pages/Calendar/components/forms/Booking/EmployeeField.vue";
import roleActions from "@/calendesk/mixins/roleActions";
import ServicesField from "@/lib/calendesk-js-library/components/ServicesField.vue";

export default {
  components: { ServicesField, EmployeeField },
  mixins: [sharedListFilters, roleActions],
  data() {
    return {
      cacheId: "availability-list-filters",
      selectedFiltersSearch: null,
      selectedFiltersEmployees: [],
      selectedFiltersServiceIds: [],
    };
  },
  computed: {
    ...mapGetters({
      categoryWithServices: "category/categoryWithServices",
    }),
    filters() {
      return {
        selectedFiltersSearch: this.selectedFiltersSearch,
        selectedFiltersEmployees: this.selectedFiltersEmployees,
        selectedFiltersServiceIds: this.selectedFiltersServiceIds,
      };
    },
  },
  methods: {
    getData() {
      const data = {};

      if (this.selectedFiltersSearch) {
        data.query = this.selectedFiltersSearch;
      }

      if (
        this.selectedFiltersEmployees &&
        this.selectedFiltersEmployees.length > 0
      ) {
        data.employee_ids = this.selectedFiltersEmployees
          .map((employee) => employee.id)
          .join(",");
      }

      if (
        this.selectedFiltersServiceIds &&
        this.selectedFiltersServiceIds.length > 0
      ) {
        data.service_ids = this.selectedFiltersServiceIds.join(",");
      }

      return data;
    },
  },
};
</script>
