<template>
  <list-buttons
    :is-hovered="isHovered"
    :extra-actions="extraActions"
    :primary-actions="primaryActions"
    @fireAction="fire"
  />
</template>
<script>
import ListButtons from "@/components/ListButtons.vue";
import availabilityActions from "@/calendesk/mixins/availabilityActions";
import roleActions from "@/calendesk/mixins/roleActions";

export default {
  components: { ListButtons },
  mixins: [availabilityActions, roleActions],
  props: {
    item: {
      type: Object,
      required: true,
    },
    isHovered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    extraActions() {
      const actions = [
        {
          action: "edit",
          color: "light",
          icon: "$pencil",
          title: this.$trans("edit"),
        },
      ];

      if (this.loggedUserCanEditEmployees) {
        actions.push({
          action: "assign_to_employees",
          color: "light",
          icon: "$clipboard-account",
          title: this.$trans("assign_to_employees"),
        });
      } else {
        actions.push({
          action: "assign_to_myself",
          color: "light",
          icon: "$clipboard-account",
          title: this.$trans("activate_work_schedule"),
        });
      }

      if (this.loggedUserCanEditServices) {
        actions.push({
          action: "assign_to_services",
          color: "light",
          icon: "$store",
          title: this.$trans("assign_to_services"),
        });
      }

      actions.push({
        action: "delete",
        color: "error",
        icon: "$trash-default",
        title: this.$trans("delete"),
      });

      return actions;
    },
    primaryActions() {
      return [
        {
          action: "edit",
          color: "light",
          icon: "$pencil",
          title: this.$trans("edit"),
        },
      ];
    },
  },
  methods: {
    fire(action) {
      const cloned = this.$helpers.cloneObject(this.item);
      switch (action) {
        case "edit":
          this.handleEditAvailability(cloned);
          break;
        case "assign_to_employees":
          this.handleAssignToEmployees(cloned);
          break;
        case "assign_to_services":
          this.handleAssignToServices(cloned);
          break;
        case "assign_to_myself":
          this.handleAssignToMyself(cloned);
          break;
        case "delete":
          this.handleDeleteAvailabilities([cloned]);
          break;
      }
    },
  },
};
</script>
